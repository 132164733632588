<template>
  <div class="new-program-details pb-12">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Program Details</h2>
          <v-btn
            v-if="!isCompleted"
            class="primary--text mr-5"
            text
            depressed
            :loading="form.$busy"
            @click="saveForLater"
          >
            Save & Finish Later
          </v-btn>

          <!--
          <v-btn
            class="px-12 mr-2"
            @click="deactivate"
            :loading="form.$busy"
            text
            depressed
          >
            Deactivate
            <v-icon> {{ icons.rm }} </v-icon>
          </v-btn>
          -->

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndNext"
            :loading="form.$busy"
            text
            depressed
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <program-details-form :form-data="form" />
      </v-col>
    </v-row>
    <ConfirmDialog ref="confirmDeactivateProgram" @confirmed="confirmed" />
  </div>
</template>

<script>
import ProgramDetailsForm from '../components/ProgramDetailsForm'
import Event from '@/services/eventBus'
import Form from '@/utils/form'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { mapActions, mapMutations, mapState } from 'vuex'
import { mdiArrowRight, mdiTrashCanOutline } from '@mdi/js'
import { pick } from 'lodash'

export default {
  name: 'NewPrgoramDetails',

  components: {
    ProgramDetailsForm,
    ConfirmDialog,
  },

  data() {
    return {
      icons: {
        next: mdiArrowRight,
        rm: mdiTrashCanOutline,
      },
      form: new Form({
        id: this.$attrs.id,
        type: 1,
        name: null,
        package: {},
        trainer: {},
        week_count: null,
        package_id: null,
        trainer_ids: [],
        description: null,
        has_deload: false,
        welcome_message: null,
        access_starts_at: null,
        deload_week_count: null,
        has_extended_validity: false,
        is_pregnancy_safe: false,
        training_programs: [],
        trainers: [],
        workout_type: null,
        has_workout_days: false,
      }),
    }
  },

  computed: {
    ...mapState({
      selectedProgram: (state) => state.programs.selectedProgram,
    }),

    isCompleted() {
      return !!this.selectedProgram.completed_at
    },
  },

  created() {
    if (this.$attrs.id) {
      this.fillForm(this.selectedProgram)
    }
  },

  methods: {
    ...mapActions({
      getProgram: 'programs/getProgram',
      saveProgram: 'programs/saveProgram',
    }),

    ...mapMutations({
      setSelectedProgram: 'programs/setSelectedProgram',
    }),

    async fetchProgram(id) {
      let { data } = await this.getProgram(id)
      this.fillForm(data)
    },

    saveForLater() {
      this.form.$busy = true
      this.form.$clearErrors()

      this.saveProgram(this.form)
        .then((res) => {
          Event.$emit('update-draft-programs-list')

          if (!this.isCompleted) {
            this.form.$timeout(() => {
              this.$router.push({ name: 'draft.programs' })
            })
          } else {
            this.form.$timeout(() => {
              this.$router.push({ name: 'completed.programs' })
            })
          }
        })
        .catch((err) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
        })
    },

    saveAndNext() {
      this.form.$busy = true
      this.form.$clearErrors()

      this.saveProgram(this.form)
        .then((data) => {
          this.form.$timeout(() => {
            this.setSelectedProgram(data)

            let endpoint = this.form.has_workout_days
              ? 'workout-days'
              : 'rich-description'
            let name = !this.isCompleted
              ? `new.program.${endpoint}`
              : `program.${endpoint}`

            this.$router.push({
              name: name,
              params: { id: data.id },
            })
          })
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    confirmed(confirmed) {},

    async deactivate() {
      try {
        const confirm = await this.$refs.confirmDeactivateProgram.open(
          'Program',
          `Are you sure you want to deactivate <b>${this.selectedProgram.name}</b> Program?`
        )

        if (!confirm) return

        this.form.$busy = true
        await this.$api.put(`programs/${this.$attrs.id}/deactivate`)

        Event.$emit('update-current-programs-list')
        this.$router.push({ name: 'completed.programs' })

        this.form.$busy = false
      } catch (e) {
        this.form.$busy = false
      }
    },

    fillForm(program) {
      program.package = program.package || {}

      this.form = new Form(
        pick(program, [
          'id',
          'name',
          'type',
          'package',
          'trainer',
          'workouts',
          'checkins',
          'package_id',
          'trainer_id',
          'week_count',
          'has_deload',
          'has_extended_validity',
          'is_pregnancy_safe',
          'description',
          'package_name',
          'welcome_message',
          'access_starts_at',
          'deload_week_count',
          'training_programs',
          'workout_type',
          'has_workout_days',
          'trainers',
        ])
      )
    },
  },

  watch: {
    selectedProgram: {
      deep: true,
      handler: function(program) {
        if (this.$attrs.id) {
          this.fillForm(this.selectedProgram)
        }
      },
    },
  },
}
</script>
