<template>
  <v-row class="bbr-program--details-form">
    <v-col cols="12" sm="2">
      <v-checkbox
        v-model="form.has_deload"
        label="Has Deload"
        color="primary"
      />
    </v-col>

    <v-col cols="12" sm="3">
      <v-checkbox
        v-model="form.has_extended_validity"
        label="Has Extended Validity"
        color="primary"
      />
    </v-col>

    <v-col cols="12" sm="3">
      <v-checkbox
        v-model="form.is_pregnancy_safe"
        label="Is Pregnancy Safe"
        color="primary"
      />
    </v-col>

    <v-col cols="12" sm="3">
      <v-checkbox
        v-model="form.has_workout_days"
        label="Has Workout Days"
        color="primary"
      />
    </v-col>

    <v-spacer />

    <!--    <v-col cols="12" sm="4">-->
    <!--      <v-menu-->
    <!--        v-model="accessDateMenu"-->
    <!--        class="bbr-challenge&#45;&#45;new-details-datepicker"-->
    <!--        transition="scale-transition"-->
    <!--        min-width="290px"-->
    <!--        :close-on-content-click="false"-->
    <!--        offset-y-->
    <!--        bottom-->
    <!--        right-->
    <!--      >-->
    <!--        <template v-slot:activator="{ on }">-->
    <!--          <v-text-field-->
    <!--            v-model="form.access_starts_at"-->
    <!--            v-on="on"-->
    <!--            label="Access Date"-->
    <!--            readonly-->
    <!--            outlined-->
    <!--            flat-->
    <!--          />-->
    <!--        </template>-->
    <!--        <v-date-picker-->
    <!--          v-model="form.access_starts_at"-->
    <!--          @input="accessDateMenu = false"-->
    <!--          scrollable-->
    <!--          no-title-->
    <!--        />-->
    <!--      </v-menu>-->
    <!--    </v-col>-->

    <v-col cols="12" sm="8">
      <v-text-field
        v-model="form.name"
        label="Program Name"
        :error-messages="form.$getError('name')"
        outlined
        flat
      />
    </v-col>

    <v-col cols="12" sm="4">
      <multiple-trainer-search-input
        v-model="form.trainers"
        label="Program Trainers"
        clearable
        outlined
        flat
        multiple
        :error-messages="form.$getError('trainer_ids')"
      />
    </v-col>

    <v-col cols="12" :sm="hasDeload ? 6 : 8">
      <search-plan-input
        v-model="form.package_id"
        label="Program Plan"
        :default-value="form.package"
        with-description
        clearable
        outlined
        flat
      />
    </v-col>

    <v-col cols="12" :sm="hasDeload ? 3 : 4">
      <v-text-field
        v-mask="'##'"
        v-model="form.week_count"
        label="Week count"
        :error-messages="form.$getError('week_count')"
        outlined
        flat
      />
    </v-col>

    <v-col v-if="hasDeload" cols="12" sm="3">
      <v-text-field
        v-mask="'##'"
        v-model="form.deload_week_count"
        label="Deload Week count"
        :error-messages="form.$getError('deload_week_count')"
        outlined
        flat
      />
    </v-col>

    <v-col cols="4">
      <v-select
        label="Workout Type"
        :error-messages="form.$getError('workout_type')"
        v-model="form.workout_type"
        :items="workoutTypes"
        item-text="text"
        item-value="id"
        outlined
        flat
      ></v-select>
    </v-col>

    <v-col cols="8">
      <training-program-search-input
        label="Training Programs"
        :error-messages="form.$getError('training_program_ids')"
        v-model="form.training_programs"
        hide-selected
        small-chips
        multiple
        outlined
        flat
        :disabled="!form.workout_type"
        :readonly="form.workout_type === 1"
        :workout-type="form.workout_type"
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="form.description"
        label="Program Description"
        auto-grow
        outlined
        flat
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="form.welcome_message"
        label="Program Welcome Message"
        auto-grow
        outlined
        flat
      />
    </v-col>
  </v-row>
</template>

<script>
import TrainingProgramSearchInput from '@/components/elements/TrainingProgramSearchInput'
import MultipleTrainerSearchInput from '@/components/elements/trainers/MultipleTrainerSearchInput'
import SearchPlanInput from '@/components/elements/plans/SearchPlanInput'
import Form from '@/utils/form'
import { map } from 'lodash'

import { mask } from 'vue-the-mask'
import { mapState } from 'vuex'

export default {
  name: 'ProgramDetailsForm',

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  directives: {
    mask,
  },

  components: {
    // SearchTrainerInput,
    SearchPlanInput,
    TrainingProgramSearchInput,
    MultipleTrainerSearchInput,
  },

  data() {
    return {
      accessDateMenu: false,
      form: this.formData,
      validities: [
        { id: 1, name: '3 Months' },
        { id: 2, name: '6 Months' },
        { id: 3, name: '12 Months' },
      ],
      workoutTypes: [
        { id: 1, text: 'Venue' },
        { id: 2, text: 'Training' },
      ],
    }
  },

  computed: {
    ...mapState({
      selectedProgram: (state) => state.programs.selectedProgram,
    }),

    isCompleted() {
      return !!this.selectedProgram.completed_at
    },

    hasDeload() {
      return !!this.form.has_deload
    },
  },

  watch: {
    formData() {
      this.form = this.formData
    },

    hasDeload(value) {
      if (!value) {
        this.form.deload_week_count = null
      }
    },
    'form.trainers': {
      handler: function(value) {
        this.form.trainer_ids = map(value, 'id')
      },
      immediate: true,
    },
    'form.training_programs': {
      handler: function(value) {
        this.form.training_program_ids = map(value, 'id')
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
input:read-only {
  cursor: default !important;
}
</style>
